* {
  box-sizing: border-box;
}

html, body {
  -webkit-overflow-scrolling : touch;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  display: none;
}

a {
  color: #4183c4;
  text-decoration: none !important;
}

.logo {
  margin: auto;
  cursor: pointer;
}

.main {
  max-width: 1200px;
  margin: auto !important;
}

.m-auto {
  margin: auto;
}

.pointer {
  cursor: pointer;
}

.gate-card {
  img {
    height: 200px !important;
  }
}

.chat-box {
  height: 400px;
  overflow: auto;
}

.user-box {
  height: 300px;
  overflow: auto;
}

.room-box {
  height: 700px;
  overflow: auto;
}

.game-title {
  text-align: center;
  max-width: 150px;
  margin: 1.5rem auto 0 auto !important;
}

.ml5 {
  margin-left: 5px;
}

.ml10 {
  margin-left: 10px !important;
}

.ml20 {
  margin-left: 30px !important;
}

.ml40 {
  margin-left: 50px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.opacity05 {
  opacity: 0.5;
}

.msg-text {
  word-break:break-all; 
  margin: 5px 20px; 
  max-width: 70%; 
  border: 1px solid #888; 
  padding: 5px 10px;
  border-radius: 5px; 
  background-color: #FCFCFC; 
  color: #555; 
  text-align: left;
  font-size: 0.9rem !important;
}

.bg-sky {
  background-color: #f1f9fb !important;
}

.text-right {
  text-align: right;
}

.msg-time {
  margin: .25em 0 .5em;
  display: flex;
  align-items: flex-end;
  padding: 5px;
}

.modal  {
  top: auto !important;
  left: auto !important;
  height: auto !important;
}

.wando-game-score {
  font-weight: bold;
  margin-top: 20px;
  font-size: 20px;
  color: rgb(214, 81, 19);
}

.wando-canvas {
  display: flex;
  margin: auto;
  border: 5px solid #154d22b5;
}

.wando-keyboard {
  width: 400px;
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 20px;
}

.soju-rain-frame {
  position: absolute;
  width: 410px;
  height: 510px;
  left: 50%;
  transform: translate(-50%, 0);
}

.wando-frame {
  width: 410px;
  height: 510px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: auto;
  border: 5px solid #154d22b5;
  font-weight: 600;
}